import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePathname } from 'next-intl/client'
import { capitalize } from 'lodash'
import classNames from 'classnames'
import dynamic from 'next/dynamic'

import {
  Icon,
  Button,
  TextLink,
  CustomImage,
  LoadingSkeleton,
  Popover,
} from '@/components'
import {
  DepthKeys,
  useOnResize,
  useLanguages,
  useCombinedRefs,
  useOnClickOutside,
} from '@/hooks'
import { ITriggerAction } from '@/components/Popover/types'
import { homePath, rem, reservationPaths } from '@/utils'
import { IRoute, TFunc } from '@/types'

import { routes } from './constants'

import mobileLogo from '@/assets/images/icons/mobile_logo.svg'
import logo from '@/assets/images/logo-enterprise.png'

const AppLanguage = dynamic(
  () => import('./components').then(module => module.AppLanguage),
  {
    ssr: false,
    loading: () => (
      <LoadingSkeleton
        width={rem(67.2)}
        height={rem(18.4)}
        className="default"
      />
    ),
  },
)

const AppLanguageMobile = dynamic(
  () => import('./components').then(module => module.AppLanguageMobile),
  {
    ssr: false,
    loading: () => (
      <LoadingSkeleton width={rem(50)} height={rem(18.4)} className="default" />
    ),
  },
)

const AppCurrency = dynamic(
  () => import('./components').then(module => module.AppCurrency),
  {
    ssr: false,
    loading: () => (
      <LoadingSkeleton width={rem(50)} height={rem(18.4)} className="default" />
    ),
  },
)

const AppCurrencyMobile = dynamic(
  () => import('./components').then(module => module.AppCurrencyMobile),
  {
    ssr: false,
    loading: () => (
      <LoadingSkeleton width={rem(50)} height={rem(18.4)} className="default" />
    ),
  },
)

const ReservationHeader = dynamic(
  () => import('./components').then(module => module.ReservationHeader),
  {
    ssr: false,
    loading: () => (
      <LoadingSkeleton borderRadius={0} width="100%" height={rem(60)} />
    ),
  },
)

export const AppHeader: React.FC = () => {
  const pathname = usePathname()
  const { t } = useLanguages()
  const [show, setShow] = useState(false)
  const [open, setOpened] = useState<number>()
  const burgerRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLTableSectionElement>(null)
  const combinedRefs = useCombinedRefs(burgerRef, menuRef)

  const toggle = useCallback(() => {
    setShow(prev => !prev)
  }, [])

  const onResize = useCallback(() => {
    if (window.innerWidth > 991) {
      setShow(false)
    }
  }, [])

  useOnResize(onResize)

  useOnClickOutside(combinedRefs, true, () => setShow(false))

  useEffect(() => {
    if (show) {
      document.body.classList.add('disable-scroll')
    } else {
      setOpened(undefined)
      document.body.classList.remove('disable-scroll')
    }
  }, [show])

  const openSubPaths = useCallback(
    (idx: number) => () => {
      setOpened(prev => (prev === idx ? undefined : idx))
    },
    [],
  )

  const onGoHome = useCallback(() => {
    setShow(false)
    window?.scrollTo?.({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const isReservation = useMemo(
    () => reservationPaths.includes(pathname),
    [pathname],
  )

  const hideRoutes = useMemo(
    () => ['/reservations/success'].includes(pathname),
    [pathname],
  )

  return (
    <header>
      {isReservation ? (
        <ReservationHeader />
      ) : (
        <>
          <section className="header-navbar">
            <TextLink href={homePath} className="header-logo">
              <CustomImage
                priority
                width={168}
                height={35.5}
                src={logo.src}
                style={{ objectFit: 'contain' }}
              />
            </TextLink>
            {!hideRoutes && (
              <div className="header-settings">
                <AppCurrency />
                <AppLanguage />
              </div>
            )}
          </section>
          {!hideRoutes && (
            <>
              <section className="mobile-header">
                <TextLink href={homePath} onClick={onGoHome} className="logo">
                  <CustomImage
                    priority
                    width={56}
                    height={56}
                    src={mobileLogo.src}
                    style={{
                      objectFit: 'contain',
                      marginLeft: rem(-10),
                      width: `${rem(56)} !important`,
                    }}
                  />
                </TextLink>
                <Button
                  ref={burgerRef}
                  onClick={toggle}
                  className="mobile-menu"
                  aria-label="Navigation menu"
                >
                  <Icon name="Hamburger" />
                </Button>
              </section>
              <section
                ref={menuRef}
                className={classNames('opened-mobile-menu', {
                  show,
                })}
              >
                <div className="opened-mobile-menu-header">
                  <AppLanguageMobile />
                  <AppCurrencyMobile />
                </div>
                <div className="opened-mobile-menu-body">
                  {routes.map((route, idx) => (
                    <React.Fragment key={idx}>
                      <div className="opened-mobile-menu-box">
                        <TextLink
                          href={route.path}
                          onClick={
                            route.subs?.length ? openSubPaths(idx) : toggle
                          }
                          className={classNames('opened-mobile-menu-link', {
                            open: open === idx,
                            single: !route.subs?.length,
                          })}
                        >
                          {capitalize(t(route.label))}
                        </TextLink>
                      </div>
                      {!!route.subs?.length && (
                        <div
                          className={classNames('expanded-links', {
                            open: open === idx,
                          })}
                        >
                          {route.subs?.map((sub, _idx) => (
                            <TextLink
                              key={_idx}
                              href={sub.path}
                              onClick={toggle}
                              className="expanded-link"
                            >
                              {t(sub.label)}
                            </TextLink>
                          ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </section>
              <section className="header-menu">
                {routes.map((route, idx) => (
                  <NavItem key={idx} {...route} />
                ))}
              </section>
            </>
          )}
        </>
      )}
    </header>
  )
}

const NavItem: React.FC<IRoute & { subs?: IRoute[]; title?: DepthKeys }> = ({
  ...route
}) => {
  const { t } = useLanguages()
  const divRef = useRef<HTMLDivElement>(null)
  const popoverRef = useRef<ITriggerAction>(null)

  const onClose = useCallback(() => {
    popoverRef.current?.onClose()
  }, [])

  const onAction = useCallback(
    (path: string, onClose: TFunc) => () => {
      if (!path) return
      onClose()
    },
    [],
  )

  useOnClickOutside(divRef, true, onClose)

  const subsLength = (route.subs?.length ?? 0) === 7 ? 4 : 3

  return (
    <div className="header-menu-nav">
      <Popover
        ref={popoverRef}
        closeOnOutside
        disabled={!route.subs?.length}
        onOpened={() => document.body.classList.add('disable-scroll')}
        onClosed={() => document.body.classList.remove('disable-scroll')}
        hasArrow={false}
        distance={0}
        trigger="on-click"
        button={
          <TextLink href={route.path} className="header-menu-link">
            {capitalize(t(route.label))}
            {!!route.subs?.length && <Icon name="menu-drop-down" />}
          </TextLink>
        }
      >
        {onClose => (
          <>
            {route?.subs?.length && (
              <div className="header-menu-overlay">
                <div ref={divRef} className="header-menu-opened-box">
                  <div className="header-menu-dropdown-menu">
                    <div className="header-menu-opened-box-wrapper">
                      {!!route.title && (
                        <h1 className="group-title">{t(route.title)}</h1>
                      )}
                      <div className="header-menu-opened-box-container">
                        <div className="header-menu-opened-box-links first">
                          {route.subs.slice(0, subsLength).map((sub, _idx) => (
                            <TextLink
                              key={_idx}
                              href={sub.path}
                              onClick={onAction(sub.path, onClose)}
                              className="header-menu-opened-box-link"
                            >
                              {t(sub.label)}
                            </TextLink>
                          ))}
                        </div>
                        {route.subs.length > 3 && (
                          <div className="header-vertical-divider"></div>
                        )}
                        <div className="header-menu-opened-box-links">
                          {route.subs.slice(subsLength).map((sub, _idx) => (
                            <TextLink
                              key={_idx}
                              href={sub.path}
                              onClick={onAction(sub.path, onClose)}
                              className="header-menu-opened-box-link"
                            >
                              {capitalize(t(sub.label))}
                            </TextLink>
                          ))}
                        </div>
                      </div>
                    </div>
                    <CustomImage
                      width={466}
                      height={398}
                      src={route.image as string}
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Popover>
    </div>
  )
}
