import {
  faqPath,
  editPath,
  termsPath,
  aboutPath,
  startPath,
  yerevanSlug,
  blogPostPath,
  carLeasePath,
  generatePath,
  contactUsPath,
  termsOfUsePath,
  luxuryCarsPath,
  generateSubPath,
  cookiePolicyPath,
  reservationsPath,
  privacyPolicyPath,
  oneWayRentalsPath,
  forBusinessesPath,
  rentInLocationPath,
  rentWithDriverPath,
  yerevanAirportSlug,
  longTermRentalsPath,
  corporateRentalsPath,
  transferServicesPath,
  monthAndMoreRentalsPath,
  transportationForEventsPath,
} from '@/utils'
import { IRoute } from '@/types'
import { DepthKeys } from '@/hooks'

import linkedInIcon from '@/assets/images/Linkedin.png'
// import whatsAppIcon from '@/assets/images/WhatsApp.png'
import facebookIcon from '@/assets/images/Facebook.png'
import instagramIcon from '@/assets/images/IG.png'
// import viberIcon from '@/assets/images/Viber.png'

export const Socials = [
  // {
  //   link: '#',
  //   icon: whatsAppIcon,
  // },
  // {
  //   link: '#',
  //   icon: viberIcon,
  // },
  {
    link: 'facebookLink',
    icon: facebookIcon,
  },
  {
    link: 'instagramLink',
    icon: instagramIcon,
  },
  {
    link: 'linkedInLink',
    icon: linkedInIcon,
  },
]

export const MainLinks: {
  to: string
  label: DepthKeys
}[] = [
  {
    to: termsOfUsePath,
    label: 'termsOfUse',
  },
  {
    to: privacyPolicyPath,
    label: 'privacyPolicy',
  },
  {
    to: cookiePolicyPath,
    label: 'cookiePolicy',
  },
]

export const RightElements: {
  title?: DepthKeys
  routes?: IRoute[]
  className?: string
  categories?: IRoute[]
  WrapEl?: React.ElementType
}[] = [
  {
    title: 'carRentalInYerevan',
    routes: [
      {
        path: generateSubPath(reservationsPath, startPath),
        label: 'startNewReservation',
      },
      {
        path: generateSubPath(reservationsPath, editPath),
        label: 'editReservation',
      },
      {
        path: generateSubPath(reservationsPath, oneWayRentalsPath),
        label: 'oneWayRentals',
      },
      {
        path: generateSubPath(reservationsPath, monthAndMoreRentalsPath),
        label: 'monthAndMoreRentals',
      },
      {
        path: generateSubPath(reservationsPath, rentWithDriverPath),
        label: 'rentCarWithDriver',
      },
      {
        path: generateSubPath(reservationsPath, luxuryCarsPath),
        label: 'luxuryCarRental',
      },
      {
        isDefault: true,
        path: process.env.NEXT_PUBLIC_INTERNATIONAL_URL as string,
        label: 'intlCarRentalLocations',
      },
    ],
  },
  {
    title: 'forBusinesses',
    routes: [
      {
        path: generateSubPath(forBusinessesPath, corporateRentalsPath),
        label: 'corporateRentals',
      },
      {
        path: generateSubPath(forBusinessesPath, longTermRentalsPath),
        label: 'longTermRentals',
      },
      {
        path: generateSubPath(forBusinessesPath, carLeasePath),
        label: 'carLease',
      },
      {
        path: generateSubPath(forBusinessesPath, transportationForEventsPath),
        label: 'solutionsForEvents',
      },
      {
        path: generateSubPath(forBusinessesPath, transferServicesPath),
        label: 'transferServices',
      },
    ],
  },
  {
    title: 'locations',
    routes: [
      {
        path: generatePath(rentInLocationPath, {
          location: yerevanSlug,
        }),
        label: 'rentCarInYerevan',
      },
      {
        path: generatePath(rentInLocationPath, {
          location: yerevanAirportSlug,
        }),
        label: 'rentCarInZvartnots',
      },
    ],
  },
  {
    WrapEl: 'div',
    className: 'd-flex flex-column',
    categories: [
      {
        path: aboutPath,
        label: 'aboutUs',
      },
      {
        isDefault: true,
        path: process.env.NEXT_PUBLIC_HOLDING_URL as string,
        label: 'enterpriseHolding',
      },
      {
        path: contactUsPath,
        label: 'contactUs',
      },
      {
        path: termsPath,
        label: 'terms&Conditions',
      },
      {
        path: faqPath,
        label: 'faq',
      },
      {
        path: blogPostPath,
        label: 'travelBlog',
      },
    ],
  },
]
