import React from 'react'

import { AppHeader, AppFooter } from '../'

interface IAppLayout {
  children: React.ReactNode
}

export const AppLayout: React.FC<IAppLayout> = ({ children }) => {
  return (
    <>
      <AppHeader />
      <main>{children}</main>
      <AppFooter />
    </>
  )
}
