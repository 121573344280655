import {
  editPath,
  startPath,
  aboutPath,
  fleetPath,
  yerevanSlug,
  carLeasePath,
  generatePath,
  bestOfferPath,
  luxuryCarsPath,
  generateSubPath,
  reservationsPath,
  forBusinessesPath,
  oneWayRentalsPath,
  rentWithDriverPath,
  rentInLocationPath,
  yerevanAirportSlug,
  longTermRentalsPath,
  transferServicesPath,
  corporateRentalsPath,
  monthAndMoreRentalsPath,
  transportationForEventsPath,
} from '@/utils'
import { IRoute } from '@/types'
import { DepthKeys } from '@/hooks'

import reservationsImg from '@/assets/images/reservations.jpeg'
import businessImg from '@/assets/images/businesses.jpeg'
import locationsImg from '@/assets/images/locations.jpeg'

export const routes: (IRoute & { subs?: IRoute[]; title?: DepthKeys })[] = [
  {
    path: '#',
    title: 'carRental',
    image: reservationsImg.src,
    label: 'reservations',
    subs: [
      {
        path: generateSubPath(reservationsPath, startPath),
        label: 'startReservation',
      },
      {
        path: generateSubPath(reservationsPath, editPath),
        label: 'editReservation',
      },
      {
        path: generateSubPath(reservationsPath, oneWayRentalsPath),
        label: 'oneWayRentals',
      },
      {
        path: generateSubPath(reservationsPath, bestOfferPath),
        label: 'bestOffer',
      },
      {
        path: generateSubPath(reservationsPath, monthAndMoreRentalsPath),
        label: 'monthAndMoreRentals',
      },
      {
        path: generateSubPath(reservationsPath, rentWithDriverPath),
        label: 'rentCarWithDriver',
      },
      {
        path: generateSubPath(reservationsPath, luxuryCarsPath),
        label: 'luxuryCarRental',
      },
    ],
  },
  {
    path: '#',
    title: 'solutionsForBusinesses',
    image: businessImg.src,
    label: 'forBusinesses',
    subs: [
      {
        path: generateSubPath(forBusinessesPath, corporateRentalsPath),
        label: 'corporateRentals',
      },
      {
        path: generateSubPath(forBusinessesPath, longTermRentalsPath),
        label: 'longTermRentals',
      },
      {
        path: generateSubPath(forBusinessesPath, carLeasePath),
        label: 'carLease',
      },
      {
        path: generateSubPath(forBusinessesPath, transportationForEventsPath),
        label: 'solutionsForEvents',
      },
      {
        path: generateSubPath(forBusinessesPath, transferServicesPath),
        label: 'transferServices',
      },
    ],
  },
  {
    path: generateSubPath(forBusinessesPath, carLeasePath),
    label: 'carLease',
  },
  {
    path: fleetPath,
    label: 'vehicles',
  },
  {
    path: '#',
    image: locationsImg.src,
    label: 'locations',
    subs: [
      {
        path: generatePath(rentInLocationPath, {
          location: yerevanSlug,
        }),
        label: 'rentCarInYerevan',
      },
      {
        path: generatePath(rentInLocationPath, {
          location: yerevanAirportSlug,
        }),
        label: 'rentCarInZvartnots',
      },
    ],
  },
  {
    path: aboutPath,
    label: 'aboutUs',
  },
]
